import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import SEO from "../components/MetaSeo";
import MiniHero from "../components/MiniHero";
import Layout from "../components/layout";

const Assinar = ({title = 'Assinar', slug = 'assinar', data: {image1, image2}}) => {

  return (
    <Layout>
      <SEO title={title} pathname={slug} />
      <MiniHero name={title} />


       
      <div className="container mx-auto" style={{padding: '0px'}}>

<section className="text-gray-700 body-font">
  <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
    <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
      <h1 className="font-family-louize sm:text-6xl text-4xl mb-4 font-medium text-gray-900">Atualidades para aqueles que tem <u>pressa</u>
      </h1>
      <p className="mb-8 leading-relaxed">
        Vá fundo onde deseja expandir seu conhecimento. 
        <br />E fique por dentro do que está acontecendo no mundo com nossa a <u>newsletter</u>.
      </p>
      <div className="flex justify-center">
        <a className="inline-flex text-white bg-red-500 border-0 py-2 px-6 focus:outline-none hover:bg-red-600 rounded text-lg" href="http://pag.ae/7WjBXq9X4" target="_blank" rel="noreferrer">Assinar Agora</a>
      </div>
    </div>
 
  </div>
</section>

<section data-section-id="2" data-category="__structures" data-component-id="73a6_23_01_awz" data-custom-component-id="11771" className="text-white body-font bg-black">
  <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
    <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
      <Img className="object-cover object-center rounded" fluid={image1.childImageSharp.fluid} />
    </div>
    <div className="lg:flex-grow md:w-1/2 pl-8 flex flex-col md:items-start md:text-left items-center text-center">
      <h1 className="font-family-louize sm:text-4xl text-3xl mb-4 font-medium text-red-400">
        A Internet é muito grande.<br />
        Vamos reduzi-la a <u>textos semanais</u>.
      </h1>

      <p className="mb-8 leading-relaxed">
        <span className="bg-red-100 text-red-500 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center"><svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="w-3 h-3" viewBox="0 0 24 24"><path d="M20 6L9 17l-5-5"></path></svg></span>
        Tudo que você precisa saber para sua carreira<br />
        <span className="bg-red-100 text-red-500 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center"><svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="w-3 h-3" viewBox="0 0 24 24"><path d="M20 6L9 17l-5-5"></path></svg></span>
        Tudo que você quer saber como um ser humano curioso<br />

        Nós reuniremos os <u>assuntos relevantes</u> para você
      </p>
    </div>
  </div>
</section>

<section data-section-id="3" data-category="__structures" data-component-id="73a6_23_01_awz" data-custom-component-id="11773" className="text-gray-700 body-font bg-red-600">
  <div className="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
    {/* <img className="lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded" alt="hero" src="https://dummyimage.com/720x600"/> */}
    <Img className="lg:w-1/2 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded" fluid={image2.childImageSharp.fluid} />
    <div className="text-center lg:w-2/3 w-full">
      <h1 className="font-family-louize sm:text-4xl text-3xl mb-4 font-medium text-orange-100">
        As semanas tendem a ser ocupadas. <br />
        Se você sente que está perdendo coisas importantes
      </h1>
      <p className="mb-8 text-orange-200">
        Garantiremos que você nunca irá perder nenhum assunto importante da atualidade.
        Portanto vamos conversar com você <u>somente nas sextas-feiras</u>.
      </p>
      <div className="flex justify-center">
        <button className="inline-flex text-white bg-red-500 border-0 py-2 px-6 focus:outline-none hover:bg-red-700 rounded text-lg" href="http://pag.ae/7WjBXq9X4" target="_blank" rel="noreferrer">Assine Agora</button>
      </div>
    </div>
  </div>
</section>

<section data-section-id="4" data-category="__structures" data-component-id="73a6_23_01_awz" data-custom-component-id="11775" className="text-red-100 bg-black body-font">
  <div className="container px-5 py-24 mx-auto">
    <div className="flex flex-col text-center w-full mb-20">
      <h2 className="text-xs text-red-500 tracking-widest font-medium title-font mb-1 sm:text-3xl text-2xl uppercase font-family-louize">Por que?</h2>
      <p className="title-font">
        A Internet nos moveu fundamentalmente para frente. <br />
        Mais notavelmente, nos deu acesso às informações do mundo.<br />
        Atualmente, existem três maneiras principais pelas quais descobrimos uma quantidade cada vez maior de conteúdo:
      </p>
    </div>


    <section className="text-red-600 body-font">
      <div className="container px-5 py-2 mx-auto">
        <div className="flex items-center lg:w-3/5 mx-auto border-b pb-10 mb-10 border-gray-200 sm:flex-row flex-col">
          <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
            <h2 className="text-lg title-font font-medium mb-2">Notícias</h2>
          </div>
        </div>
        <div className="flex items-center lg:w-3/5 mx-auto border-b pb-10 mb-10 border-gray-200 sm:flex-row flex-col">
          <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
            <h2 className="text-lg title-font font-medium mb-2">Midias sociais</h2>
          </div>
        </div>
        <div className="flex items-center lg:w-3/5 mx-auto sm:flex-row flex-col">
          <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
            <h2 className="text-lg title-font font-medium mb-2">Pesquisa</h2>
          </div>
        </div>



        <div className="flex flex-col text-center w-full my-10">
          <h3 className="sm:text-3xl text-2xl font-medium title-font text-red-100">
            Acreditamos que uma quarta categoria está faltando:
          </h3>

        </div>

        <div className="flex items-center lg:w-3/5 mx-auto sm:flex-row flex-col bg-gray-900 rounded p-2">
          <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
            <h2 className="text-4xl font-family-louize font-medium mb-2 underline">Relevância</h2>
            <p className="text-red-100">Damos um passo para trás para nos concentrar no que é mais relevante.</p>
          </div>
        </div>


      </div>
    </section>
  </div>
</section>



<section className="text-gray-700 body-font bg-red-dark">
  <div className="container px-5 py-24 mx-auto flex items-center md:flex-row flex-col">
    <div className="flex flex-col md:pr-10 md:mb-0 mb-6 pr-0 w-full md:w-auto md:text-left text-center">
      <h2 className="text-xs text-orange-200 tracking-widest font-medium title-font mb-1 uppercase">Teste-Grátis</h2>
      <h1 className="md:text-4xl text-2xl font-medium title-font text-orange-100 font-family-louize">Baixar Amostra da Newsletter</h1>
    </div>
    <div className="flex md:ml-auto md:mr-0 mx-auto items-center flex-shrink-0">
      <button className="bg-white hover:bg-orange-100 inline-flex py-3 px-5 rounded-lg items-center  focus:outline-none">
        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-6 h-6"viewBox="0 0 24 24"><path d="M8 17l4 4 4-4m-4-5v9"></path><path d="M20.88 18.09A5 5 0 0018 9h-1.26A8 8 0 103 16.29"></path></svg>
        <span className="ml-4 flex items-start flex-col leading-none">
       
          <a className="title-font font-medium" href="https://airtable.com/shrzIt9D0wkBi0Vyd" target="_blank" rel="noreferrer">LER <u>AGORA</u></a>
        </span>
      </button>
    </div>
  </div>
</section>


<section  className="text-gray-700 body-font overflow-hidden bg-white">

    <div className="flex flex-col text-center w-full my-20">
      <h2 className="text-xs text-red-500 tracking-widest font-medium font-family-louize mb-1 sm:text-3xl text-2xl uppercase">Quanto?</h2>
      <h3 className="sm:text-2xl text-xl text-gray-900 tracking-widest font-medium font-family-louize mb-1  uppercase">Atualidades + História + Cultura</h3>
    </div>


  <div className="container px-5 py-24 mx-auto flex flex-wrap">
    <div className="lg:w-1/4 my-48  lg:block">
      <div className="mt-px border-t border-gray-300 border-b border-l rounded-tl-lg rounded-bl-lg overflow-hidden">
        <p className="text-gray-900 h-12 text-center px-4 flex items-center justify-start">Newsletter Semanal</p>
        <p className="bg-gray-100 text-gray-900 h-12 px-4 flex items-center justify-start">Questões comentadas</p>
        <p className="text-gray-900 h-12  px-4 flex items-center justify-start">Vídeos respostas</p>
        <p className="bg-gray-100 text-gray-900 h-12 px-4 flex items-center justify-start">Acesso ao Grupo no telegram para tirar dúvidas</p>
      </div>
    </div>
    <div className="flex lg:w-3/4 w-full flex-wrap lg:border border-gray-300 rounded-lg">
      <div className="lg:w-1/3 lg:-mt-px w-full mb-10 lg:mb-0 border-2 rounded-lg border-red-500 relative">
        <span className="bg-red-500 text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl rounded-tr">POPULAR</span>
        
        <div className="px-2 text-center h-48 flex flex-col items-center justify-center">
          <h3 className="tracking-widest uppercase">Para Apressados</h3>
          <h2 className="text-5xl text-gray-900 font-medium flex items-center justify-center leading-none mb-4 mt-2">R$5
            <span className="text-gray-600 text-base ml-1">/mês</span>
          </h2>
        </div>

        <p className="text-green-600 text-center h-12 flex items-center justify-center">
          <span className="w-5 h-5 inline-flex items-center justify-center bg-green-500 text-white rounded-full flex-shrink-0">
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="w-3 h-3" viewBox="0 0 24 24">
              <path d="M20 6L9 17l-5-5"></path>
            </svg>
          </span>
        </p>

        <p className="bg-gray-100 text-gray-600 text-center h-12 flex items-center justify-center">
          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.2" className="w-5 h-5 text-gray-500" viewBox="0 0 24 24">
            <path d="M18 6L6 18M6 6l12 12"></path>
          </svg>
        </p>

        <p className="bg-gray-100 text-gray-600 text-center h-12 flex items-center justify-center">
          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.2" className="w-5 h-5 text-gray-500" viewBox="0 0 24 24">
            <path d="M18 6L6 18M6 6l12 12"></path>
          </svg>
        </p>


        <p className="text-gray-600 text-center h-12 flex items-center justify-center">
          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.2" className="w-5 h-5 text-gray-500" viewBox="0 0 24 24">
            <path d="M18 6L6 18M6 6l12 12"></path>
          </svg>
        </p>

        <div className="border-t border-gray-300 p-6 text-center rounded-bl-lg">
          <a href="http://pag.ae/7WjBXq9X4" target="_blank" rel="noreferrer" className="flex items-center mt-auto text-white bg-red-600 border-0 py-2 px-4 w-full focus:outline-none hover:bg-red-700 rounded">
            Assinar
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-auto" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </a>
          <p className="text-xs text-gray-500 mt-3">A melhor opção para curiosos</p>
        </div>
      </div>

      <div className="lg:w-1/3 w-full lg:mt-px border-2 border-gray-300 lg:border-none rounded-lg lg:rounded-none">

        <div className="px-2 text-center h-48 flex flex-col items-center justify-center">
          <h3 className="tracking-widest uppercase">Para Concursos</h3>
          <h2 className="text-5xl text-gray-900 font-medium flex items-center justify-center leading-none mb-4 mt-2">R$16,90
            <span className="text-gray-600 text-base ml-1">/mês</span>
          </h2>
        </div>

        <p className="text-green-600 text-center h-12 flex items-center justify-center">
          <span className="w-5 h-5 inline-flex items-center justify-center bg-green-500 text-white rounded-full flex-shrink-0">
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="w-3 h-3" viewBox="0 0 24 24">
              <path d="M20 6L9 17l-5-5"></path>
            </svg>
          </span>
        </p>

        <p className="text-green-600 text-center h-12 flex items-center justify-center">
          <span className="w-5 h-5 inline-flex items-center justify-center bg-green-500 text-white rounded-full flex-shrink-0">
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="w-3 h-3" viewBox="0 0 24 24">
              <path d="M20 6L9 17l-5-5"></path>
            </svg>
          </span>
        </p>

        <p className="text-green-600 text-center h-12 flex items-center justify-center">
          <span className="w-5 h-5 inline-flex items-center justify-center bg-green-500 text-white rounded-full flex-shrink-0">
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="w-3 h-3" viewBox="0 0 24 24">
              <path d="M20 6L9 17l-5-5"></path>
            </svg>
          </span>
        </p>

        <p className="text-green-600 text-center h-12 flex items-center justify-center">
          <span className="w-5 h-5 inline-flex items-center justify-center bg-green-500 text-white rounded-full flex-shrink-0">
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="w-3 h-3" viewBox="0 0 24 24">
              <path d="M20 6L9 17l-5-5"></path>
            </svg>
          </span>
        </p>


        <div className="p-6 text-center border-t border-gray-300">
        <a href="http://pag.ae/7WmvALUYM" target="_blank" rel="noreferrer" className="flex items-center mt-auto text-white bg-red-600 border-0 py-2 px-4 w-full focus:outline-none hover:bg-red-700 rounded">
            Assinar
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-auto" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </a>
          <p className="text-xs text-gray-500 mt-3">A melhor opção para quem está estudando para concursos.</p>
        </div>
      </div>

      <div className="lg:w-1/3 w-full lg:mt-px border-2 border-gray-300 lg:border-none rounded-lg lg:rounded-none">
        <div className="px-2 text-center h-48 flex flex-col items-center justify-center">
          <h3 className="tracking-widest uppercase">para ENEM</h3>
          <h2 className="text-5xl text-gray-900 font-medium flex items-center justify-center leading-none mb-4 mt-2">R$16,90
            <span className="text-gray-600 text-base ml-1">/mês</span>
          </h2>
        </div>

        <p className="text-green-600 text-center h-12 flex items-center justify-center">
          <span className="w-5 h-5 inline-flex items-center justify-center bg-green-500 text-white rounded-full flex-shrink-0">
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="w-3 h-3" viewBox="0 0 24 24">
              <path d="M20 6L9 17l-5-5"></path>
            </svg>
          </span>
        </p>

        <p className="text-green-600 text-center h-12 flex items-center justify-center">
          <span className="w-5 h-5 inline-flex items-center justify-center bg-green-500 text-white rounded-full flex-shrink-0">
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="w-3 h-3" viewBox="0 0 24 24">
              <path d="M20 6L9 17l-5-5"></path>
            </svg>
          </span>
        </p>

        <p className="text-green-600 text-center h-12 flex items-center justify-center">
          <span className="w-5 h-5 inline-flex items-center justify-center bg-green-500 text-white rounded-full flex-shrink-0">
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="w-3 h-3" viewBox="0 0 24 24">
              <path d="M20 6L9 17l-5-5"></path>
            </svg>
          </span>
        </p>

        <p className="text-green-600 text-center h-12 flex items-center justify-center">
          <span className="w-5 h-5 inline-flex items-center justify-center bg-green-500 text-white rounded-full flex-shrink-0">
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="w-3 h-3" viewBox="0 0 24 24">
              <path d="M20 6L9 17l-5-5"></path>
            </svg>
          </span>
        </p>



        <div className="p-6 text-center border-t border-gray-300">
          <button className="flex items-center mt-auto text-white bg-gray-600 border-0 py-2 px-4 w-full focus:outline-none hover:bg-red-700 rounded cursor-not-allowed">
            EM BREVE
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-auto" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </button>
          <p className="text-xs text-gray-500 mt-3">A melhor opção para quem está estudando para ENEM.</p>
        </div>
      </div>
    </div>
  </div>
</section>


<section className="text-gray-700 body-font bg-red-dark">
  <div className="container px-5 py-24 mx-auto flex items-center md:flex-row flex-col">
    <div className="flex flex-col md:pr-10 md:mb-0 mb-6 pr-0 w-full md:w-auto md:text-left text-center">
      <h2 className="text-xs text-orange-200 tracking-widest font-medium title-font mb-1 uppercase">Teste-Grátis</h2>
      <h1 className="md:text-4xl text-2xl font-medium title-font text-orange-100 font-family-louize">Baixar Apostila Atualidades para Concursos 2020</h1>
    </div>
    <div className="flex md:ml-auto md:mr-0 mx-auto items-center flex-shrink-0">
      <button className="bg-white hover:bg-orange-100 inline-flex py-3 px-5 rounded-lg items-center  focus:outline-none">
        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-6 h-6"viewBox="0 0 24 24"><path d="M8 17l4 4 4-4m-4-5v9"></path><path d="M20.88 18.09A5 5 0 0018 9h-1.26A8 8 0 103 16.29"></path></svg>
        <span className="ml-4 flex items-start flex-col leading-none">
       
          <a className="title-font font-medium" href="https://airtable.com/shrOJykxiY6YqTOqv" target="_blank" rel="noreferrer">LER <u>AGORA</u></a>
        </span>
      </button>
    </div>
  </div>
</section>

<section  className="text-gray-700 body-font bg-white">
  <div className="container px-5 py-24 mx-auto flex flex-wrap">

    <div className="flex flex-col text-center w-full mb-20">
      <h2 className="text-xs text-red-500 tracking-widest font-medium font-family-louize mb-1 sm:text-3xl text-2xl uppercase">Dúvidas</h2>
    </div>

    <div className="flex relative pt-10 pb-20 sm:items-center md:w-2/3 mx-auto">
      <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
        <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
      </div>
      <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-red-500 text-white relative z-10 title-font font-medium text-sm">1</div>
      <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
        <div className="flex-shrink-0 w-24 h-24 bg-red-100 text-red-500 rounded-full inline-flex items-center justify-center">
          <svg x="0px" y="0px" viewBox="0 0 34.958 24" stroke="currentColor" fill="currentColor" className="w-12 h-12" >
            <path d="M 31.952 0 L 3.079 0 C 1.381 0 0 1.381 0 3.079 L 0 20.921 C 0 22.619 1.381 24 3.079 24 L 31.952 24 C 33.649 24 35.03 22.619 35.03 20.921 L 35.03 3.079 C 35.03 1.381 33.649 0 31.952 0 Z M 31.55 2.053 L 31.139 2.395 L 18.737 12.722 C 18.029 13.311 17.001 13.311 16.294 12.722 L 3.892 2.395 L 3.481 2.053 L 31.55 2.053 Z M 2.053 3.534 L 12.157 11.949 L 2.053 18.674 L 2.053 3.534 Z M 31.952 21.947 L 3.079 21.947 C 2.583 21.947 2.168 21.594 2.073 21.126 L 13.803 13.319 L 14.98 14.299 C 15.715 14.911 16.615 15.217 17.515 15.217 C 18.415 15.217 19.316 14.911 20.05 14.299 L 21.227 13.319 L 32.957 21.126 C 32.862 21.594 32.447 21.947 31.952 21.947 Z M 32.978 18.674 L 22.873 11.949 L 32.978 3.534 L 32.978 18.674 Z"/>
        </svg>
        </div>

        <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
          <h2 className="font-medium title-font text-gray-900 mb-1 text-xl">COMO É FEITA A ENTREGA?</h2>
          <p className="leading-relaxed">
            A entrega é 100% online. Você receberá seu acesso por e-mail.
          </p>
        </div>
      </div>
    </div>
    <div className="flex relative pb-20 sm:items-center md:w-2/3 mx-auto">
      <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
        <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
      </div>
      <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-red-500 text-white relative z-10 title-font font-medium text-sm">2</div>
      <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
        <div className="flex-shrink-0 w-24 h-24 bg-red-100 text-red-500 rounded-full inline-flex items-center justify-center">
        <svg className="text-red-500 w-12 h-12 mb-3 inline-block" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" viewBox="0 0 24 24"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
        </div>
        <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
          <h2 className="font-medium title-font text-gray-900 mb-1 text-xl">POR QUANTO TEMPO VOU TER ACESSO?</h2>
          <p className="leading-relaxed">
            O acesso dura enquanto seu plano estiver ativo. 
          </p>
        </div>
      </div>
    </div>
    <div className="flex relative pb-20 sm:items-center md:w-2/3 mx-auto">
      <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
        <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
      </div>
      <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-red-500 text-white relative z-10 title-font font-medium text-sm">3</div>
      <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
        <div className="flex-shrink-0 w-24 h-24 bg-red-100 text-red-500 rounded-full inline-flex items-center justify-center">
        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="text-red-500 w-12 h-12 mb-3 inline-block" viewBox="0 0 24 24"><path d="M8 17l4 4 4-4m-4-5v9"></path><path d="M20.88 18.09A5 5 0 0018 9h-1.26A8 8 0 103 16.29"></path></svg>
        </div>
        <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
          <h2 className="font-medium title-font text-gray-900 mb-1 text-xl">QUANDO TEREI ACESSO?</h2>
          <p className="leading-relaxed">
            Imediatamente após sua inscrição e confirmação de pagamento. Você receberá um e-mail da nossa equipe e um e-mail da plataforma.
          </p>
        </div>
      </div>
    </div>
    <div className="flex relative pb-10 sm:items-center md:w-2/3 mx-auto">
      <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
        <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
      </div>
      <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-red-500 text-white relative z-10 title-font font-medium text-sm">4</div>
      <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
        <div className="flex-shrink-0 w-24 h-24 bg-red-100 text-red-500 rounded-full inline-flex items-center justify-center">
        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-12 h-12" viewBox="0 0 24 24">
            <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
          </svg>
        </div>
        <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
          <h2 className="font-medium title-font text-gray-900 mb-1 text-xl">OS DADOS DO MEU CARTÃO ESTÃO SEGUROS?</h2>
          <p className="leading-relaxed">
            Sim. 100% seguros. Todo o processo de compra é gerenciado pelo PagSeguro, maior plataforma de pagamentos do Brasil, através de uma transação criptografada com 128 bits.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section data-section-id="7" data-category="__structures" data-component-id="73a6_23_01_awz" data-custom-component-id="11783" className="text-gray-700 body-font relative">
  <div className="container px-5 py-24 mx-auto">
    <div className="flex flex-col text-center w-full mb-12">
      <h1 className="sm:text-3xl text-2xl font-medium font-family-louize mb-4 text-gray-900">Mais Dúvidas?</h1>
      <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
        Estamos aqui para te ajudar
      </p>
    </div>
    <div className="lg:w-1/2 md:w-2/3 mx-auto">
      <div className="flex flex-wrap -m-2">


        <div className="p-2 w-full pt-8 mt-8 border-t border-gray-200 text-center">
          <a className="text-red-500">contato@profregadas.com</a>
          <br />
          <a className="leading-normal my-5 underline hover:no-underline" href="https://t.me/AvillaRegadas">
            Telegram
          </a>

        </div>
      </div>
    </div>
  </div>
</section>
</div>



    </Layout>
  );
};

export default Assinar;

export const pageQuery = graphql`
  query {
    image1: file(name: { eq: "assinar-1" }) {
        childImageSharp {
            fluid(quality: 100, maxWidth: 1800) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
        }
    }

    image2: file(name: { eq: "assinar-2" })  {
        childImageSharp {
            fluid(quality: 100, maxWidth: 1800) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
        }
    }
  }
`;